<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Enrollment Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex gap-2 align-items-center">
              <div class="">
                <label class="col-form-label me-1">From Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="from_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    :config="flatPickrDateConfig"
                    @change="
                      getFilterData(from_date, to_date, moduleName, trainer)
                    "
                  ></flat-pickr>
                </div>
              </div>

              <div>
                <label class="col-form-label ms-2 me-1">To Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="to_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    :config="flatPickrDateConfig"
                    @change="
                      getFilterData(from_date, to_date, moduleName, trainer)
                    "
                  ></flat-pickr>
                </div>
              </div>

              <div>
                <label class="col-form-label ms-2 me-1">Module</label>
                <div style="width: 200px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName.id"
                    @option:selected="
                      getFilterData(from_date, to_date, moduleName, trainer)
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div>
                <label class="col-form-label ms-2 me-1">Trainer</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="trainer"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != trainer.id"
                    @option:selected="
                      getFilterData(from_date, to_date, moduleName, trainer)
                    "
                  >
                  </v-select>
                </div>
              </div>

              <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
            </div>

            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="enrollment-table"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th style="width: 150px">No.</th>
                    <th style="width: 200px">From Date</th>
                    <th style="width: 300px">Module Title</th>
                    <th style="width: 200px">Trainer</th>
                    <th style="width: 150px">Enrollment Count</th>
                    <th style="width: 150px">Attendance Count</th>
                    <th class="d-none">To Date</th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="(item, index) in enrollmentList" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.from_date }}</td>
                    <td>{{ item.module }}</td>
                    <td>
                      {{
                        employeeLists
                          .filter((emp) => item.trainers.includes(emp.emp_id))
                          .map((trainer) => trainer.name)
                          .join(", ")
                      }}
                    </td>
                    <td>
                      {{ item.enrollment_count }}
                    </td>
                    <td>{{ item.attendance_count }}</td>
                    <td class="d-none">{{ item.to_date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
// import { months } from "../../../utlis/months";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
// eslint-disable-next-line no-unused-vars
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
    badgeSuccess,
  },
  data() {
    return {
      years: [],
      months: [],
      modules: [],
      trainers: [],
      year: "",
      month: "",
      from_date: "",
      to_date: "",
      moduleName: "",
      trainer: "",
      enrollmentList: [],
      employeeLists: [],
      title: "Enrollment List Report",
      headers: [
        "No.",
        "Training Date",
        "Module Title",
        "Trainer",
        "Enrollment Count",
        "Attendance Count",
      ],
      flatPickrDateConfig: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },
      forExportingData: null,
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },

  methods: {
    async getAllEmployee() {
      this.loading = true;
      await axios.get(`${this.baseUrlHRIS}api/employees`).then((response) => {
        this.employeeLists = response.data.data;
        this.loading = false;
      });
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    async getAllEnrollment() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v2/enrollment-list`)
        .then((response) => {
          this.enrollmentList = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.loading = false;
          this.$Progress.finish();
        });
      $("#enrollment-table").DataTable().draw();
    },
    getFilterData(from_date, to_date, moduleName, trainer) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        let fromDateFilter = from_date;
        let fromDateStage = data[1];
        let toDateFilter = to_date;
        let toDateStage = data[6];
        let moduleFilter = moduleName?.name;
        let moduleStage = data[2];
        let trainerFilter = trainer?.name;
        let trainerStage = data[3];

        console.log(trainerFilter, "trainer", trainerStage);

        if (fromDateFilter && toDateFilter) {
          const itemDate = new Date(data[6]);
          if (
            itemDate >= new Date(from_date) &&
            itemDate <= new Date(to_date) &&
            (moduleFilter == moduleStage || !moduleFilter) &&
            (trainerFilter == trainerStage || !trainerFilter)
          ) {
            return true;
          }
          return false;
        } else if (
          (fromDateFilter == fromDateStage || !fromDateFilter) &&
          (toDateFilter == toDateStage || !toDateFilter) &&
          (moduleFilter == moduleStage || !moduleFilter) &&
          (trainerFilter == trainerStage || !trainerFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#enrollment-table").DataTable().draw();
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#enrollment-table").DataTable().draw();
    },
    refreshData() {
      this.from_date = "";
      this.to_date = "";
      this.moduleName = "";
      this.trainer = "";
      this.enrollmentList = [];
      this.clearFilter();
      this.getAllModules();
      this.getAllEnrollment();
    },

    exportPdfClick() {
      const dataArray = $("#enrollment-table")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[6] = ""; //delete to date
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#enrollment-table")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[6] = ""; //delete to date
        sheetData.push(item);
      });
      exportExcel(sheetData, "Enrollment List");
      this.excelLoading = false;
    },
  },
  async created() {
    this.clearFilter();
    if (this.$store.getters["odoo/getAllEmployees"].length > 0) {
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
    } else {
      await this.getAllEmployee();
    }
    await this.getAllModules();
    await this.getAllEnrollment();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
